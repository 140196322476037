
.cvContainer {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 500px
}

.appContainer {
    padding-top: 30px;
    margin: auto;
    max-width: 960px;
}

.sectionIcon {
    font-size: 20px;
    margin-right: 0.75em;
    border-radius: 100%;
    background: #797EF6;
    color: white;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    align-self: center;
}

.skillCategoryIcon {
    font-size: 20px;
    margin-right: 0.75em;
    border-radius: 100%;
    background: #797EF6;
    color: white;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.skillName {
    width: 150px;
}


.certificateName {
    width: 400px;
}
.regularContent {
    display: block
}

.printableContent {
    display:none
}

.sectionIcon svg {
    margin: 0 auto;
}

.sectionTitle {
    margin-top: 1rem;
}

.contactListContainer {
    /* margin-top: 0px; */
}

.contactListItem {

}

.sectionContainer {
    margin: 1rem;
}

.contactListItemContent {
    color: hsl(0, 0%, 96%);
    margin-left: 10px;
}

.companyTitle {
    display: inline;
}

.companyMeta, .projectMeta {
    padding: 1em;
}

.projectTitle {
    display: inline;
}

.sectionContent {
    padding: 1em;
}

.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid hsl(0, 0%, 71%);
    max-width: 500px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

hr {
    margin: 0;
}

body {
    background-color: #f5f5f5;
}


.project-group:not(:first-of-type) {
    margin-top: 30px;
}

.certificateBadgeImage { 
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    border: 0px;
    display: table-cell;
    padding: 0px;
}

@media print {
    .sectionContainer {
        padding: 0px;
        margin: 0px;
    }

    .certificateBadge { 
        filter: grayscale(1);
    }
    
    a {
        color: grey;
        cursor: pointer;
        text-decoration: none;
        text-decoration: underline;
    }

    a.contactListItemContent {
        color: #f5f5f5;
        margin-left: 10px;
        text-decoration: underline;
    }
    
    .skillStarIcon {
        color: silver !important
    }

    .sectionIcon {
        font-size: 20px;
        margin-right: 0.75em;
        border-radius: 100%;
        background: grey;
        color: white;
        width: 48px;
        height: 48px;
        display: inline-flex;
        align-items: center;
        align-self: center;
    }

    .skillCategoryIcon {
        font-size: 20px;
        margin-right: 0.75em;
        border-radius: 100%;
        background: grey;
        color: white;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        align-self: center;
        justify-content: center;
    }

    .commonListContainer {
        padding: 0;
        margin: 0;
    }

    .avoidBreakingOnPrint {
        page-break-inside: avoid;
    }

    body {
        background-color: white;
    }

    .stickyNavBar {
        display: none !important;
    }
    .appContainer {
        max-width: 100%;
        padding: 0;
    }

    .sectionContainer {
        padding: 0;
    }

    .regularContent {
        display: none
    }
    
    .printableContent {
        display: block
    }
}

.contactListIcon {
    text-align: center;
    width: 14px;
}

divTable {
    display: table;
    margin: 0 auto
}

.divTableRow {
    display: table;
    width: 100%;
    margin: 0 auto
}

.divTableCell {
    border: 0px;
    display: table-cell;
    padding: 3px 30px;
    text-align: center;
}

.divTableBody {
    display: table-row-group;
}

.skillTableRow {
    display: table-row;
    width: 100%;
}

.skillTableCell {
    border: 0px;
    display: table-cell;
    padding: 3px 30px;
}

.skillStarIcon {
    color: #ffb703
}
.skillTableBody {
    display: table-row-group;
}

.stickyNavBar {
    position: fixed;
    overflow: hidden;
    background-color: #1AA7EC;
    top: 0px;
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    z-index: 100;
}

.stickyNavBar a {
    color: white;
}

.responsiveStickyNavBar {
    display: none !important;
}

.hamburgerMenu {
    padding-right: 10px;
}
.pdfDownload {
    padding: 30px;
}

.rightNavBarSection {
    text-align: right;
    margin: 5px;
}
.pdfPageBreak {
    page-break-after: always
}

@media screen and (min-width: 650px) {

    .hamburgerMenu{
        display: none !important;
    }
}
@media screen and (max-width: 650px) {

    .divTableCell{
        display: none !important;
    }
}
